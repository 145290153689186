import React from 'react';
import Image from 'next/image';

interface ButtonProps {
  bottomLine: string;
}

export default function BottomLine({ bottomLine }: ButtonProps) {
  return (
    <section className='relative w-full h-6 -mt-1 lg:mt-auto'>
      <Image
          className='object-fill lg:object-cover'
          src={`/bottom/${bottomLine}.webp`}
          alt='background bottom line'
          fill
          loading='lazy'
      />
    </section>
  );
}
