import React, { ReactNode } from 'react';
import Image from 'next/image';

interface ButtonProps {
  children: ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  button: string;
}

export default function GameButton({
  children,
  className,
  button,
  onClick,
  ...props
}: ButtonProps) {

  return (
    <button
      className={`relative text-white text-center font-georgia font-[700] cursor-pointer group hover:scale-90 transition duration-500 ease-in-out ${className}`}
      onClick={onClick}
      {...props}
    >
      <div className='absolute inset-0'>
        <Image
            className='object-fill'
            src={`/elements/${button}`}
            alt='game button'
            fill
            loading='lazy'
        />
      </div>

      <div className='absolute inset-0 flex justify-center items-center'>
        <div className='w-full h-full group-hover:opacity-[0.3] opacity-10 group-focus:opacity-40 bg-buttonGradient transition duration-200 ease-in-out'></div>
      </div>
      <div className='relative'>
        {children}
      </div>
    </button>
  );
}
